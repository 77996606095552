<template>
  <div class="mod-tit">
    <h3 v-if="!$slots.left">{{ title }}</h3>
    <slot name="left" v-else></slot>
    <template v-if="!$slots.right">
      <a class="more" v-if="more" :href="more" target="_blank">
        {{ moreText }}
        <DoubleRightOutlined class="icon" />
      </a>
    </template>
    <slot name="right" v-else></slot>
  </div>
</template>

<script>
export default {
  name: "ModTit",
  props: {
    title: {
      type: String,
      default: "",
    },
    moreText: {
      type: String,
      default: "更多",
    },
    more: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="less" scoped>
.mod-tit {
  .mixinFlex(space-between; center);
  padding: 0 0 20px;
  line-height: 27px;
  h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;
  }
  .more {
    color: @color-theme;
    font-size: 12px;
    .mixinFlex(flex-start; center);
    transition: all 0.3s;
    .icon {
      color: @color-theme;
      margin-left: 4px;
      transition: all 0.3s;
      font-size: 12px;
    }
  }
}
</style>
