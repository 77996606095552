<template>
  <div class="lecturer">
    <div class="content">
      <ModTit
        :title="module.langNamesObj[langNamesEnum[languageActive]]"
        :moreText="$t('XB_More')"
        more="/lecturer"
        v-if="module.configuration.titleSetting.isShow"
      />
      <div class="card">
        <template v-if="dataSource.length">
          <a
            class="item"
            v-for="(item, index) in dataSource"
            :key="index"
            :href="`/lecturer/detail?id=${item.lecturerId}&ddtab=true`"
            target="_blank"
          >
            <div
              class="cover"
              :style="{
                backgroundImage: item.portrait
                  ? 'url(' + item.portrait + ')'
                  : 'url(' + require('@/assets/image/teacher_avatar.png') + ')',
              }"
            >
              <span class="level">{{ item.levelName }}</span>
            </div>
            <div class="info">
              <div class="name">
                <OpenData
                  type="userName"
                  :openid="item.lecturerName"
                  v-if="item.lecturerType == 1"
                /><span v-else>{{ item.lecturerName }}</span>
              </div>
              <div class="intro-box">
                <div class="intro">
                  {{ $t("Home.introduce")
                  }}<!-- 介绍 -->：{{ item.intro ? item.intro : $t("Home.None")
                  }}<!-- 暂无 -->
                </div>
              </div>
            </div>
          </a>
        </template>
        <div class="empty-box" v-else>
          <a-empty
            :image="require('@/assets/image/empty.png')"
            :image-style="{
              height: '144px',
            }"
          >
            <template #description>
              <div class="empty-tips">{{ $t("Pub_Lab_NoData") }}</div>
            </template>
          </a-empty>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import OpenData from "@/components/OpenData.vue";
import ModTit from "./ModTit.vue";
export default {
  name: "lecturer",
  components: {
    OpenData,
    ModTit,
  },
  props: {
    module: {
      type: Object,
      default: () => {
        return {};
      },
    },
    langNamesEnum: {
      type: Object,
      default: () => {
        return {};
      },
    },
    languageActive: {
      type: String,
      default: "",
    },
    dataSource: {
      type: Array,
      default: () => {
        return [];
      },
    },
  },
};
</script>

<style lang="less" scoped>
.lecturer {
  .content {
    max-width: 1200px;
    margin: 0 auto;
    .card {
      .mixinFlex();
      flex-wrap: wrap;
      .item {
        background-color: #fff;
        margin-bottom: 20px;
        box-shadow: 0 0 10px rgba(148, 148, 148, 0.1);
        width: calc((100% - 20px * (6 - 1)) / 6);
        min-height: 276.6px;
        margin-right: 20px;
        position: relative;
        &:nth-child(6n) {
          margin-right: 0;
        }
        .cover {
          background-size: 100% 100%;
          background-position: center;
          width: 100%;
          height: 226.6px;
          position: relative;
          .level {
            position: absolute;
            bottom: 0;
            left: 0;
            height: 30px;
            padding: 2px 12px;
            color: #fff;
            font-size: 14px;
            .mixinEllipsis(26px);
            z-index: 1;
            background-color: rgba(55, 125, 255, 0.7);
          }
        }
        .info {
          position: absolute;
          top: 226.6px;
          left: 0;
          right: 0;
          z-index: 1;
          background-color: #fff;
          box-shadow: 0 0 10px rgba(148, 148, 148, 0.1);
          .name {
            color: #333;
            font-size: 16px;
            font-weight: 600;
            padding: 12px;
            & > span {
              .mixinEllipsis(26px);
            }
          }
          .intro-box {
            padding: 0 12px;
            height: 0;
            transition: all 0.3s ease;
            .intro {
              color: #999;
              font-size: 14px;
              font-weight: 400;
              .mixinEllipsis(78px; 3);
              min-height: 0;
              height: 0;
              transition: all 0.3s ease;
            }
          }
        }
        &:hover {
          .intro-box {
            height: 90px;
            padding: 0 12px 12px;
            .intro {
              min-height: 78px;
              height: 78px;
            }
          }
        }
      }
    }
  }
}
.portal-col-70 {
  .lecturer {
    .content {
      .card {
        .item {
          width: calc((100% - 20px * (4 - 1)) / 4);
          min-height: 289px;
          &:nth-child(6n) {
            margin-right: 20px;
          }
          &:nth-child(4n) {
            margin-right: 0;
          }
          .cover {
            width: 100%;
            height: 239px;
          }
          .info {
            top: 239px;
          }
        }
      }
    }
  }
}
.portal-col-50 {
  .lecturer {
    .content {
      .card {
        .item {
          width: calc((100% - 20px * (3 - 1)) / 3);
          &:nth-child(3n) {
            margin-right: 0;
          }
        }
      }
    }
  }
}
.portal-col-30 {
  .lecturer {
    .content {
      .card {
        .item {
          width: calc((100% - 20px * (2 - 1)) / 2);
          min-height: 236px;
          &:nth-child(2n) {
            margin-right: 0;
          }
          .cover {
            width: 100%;
            height: 186px;
          }
          .info {
            top: 186px;
          }
        }
      }
    }
  }
}
.portal-col-33 {
  .lecturer {
    .content {
      .card {
        .item {
          width: calc((100% - 20px * (2 - 1)) / 2);
          &:nth-child(2n) {
            margin-right: 0;
          }
        }
      }
    }
  }
}
.portal-col-35 {
  .lecturer {
    .content {
      .card {
        .item {
          width: calc((100% - 20px * (2 - 1)) / 2);
          min-height: 289px;
          &:nth-child(2n) {
            margin-right: 0;
          }
          .cover {
            width: 100%;
            height: 239px;
          }
          .info {
            top: 239px;
          }
        }
      }
    }
  }
}
.empty-box {
  padding: 70px 0;
  width: 100%;
  .empty-tips {
    color: #999;
    font-size: 14px;
    margin-top: 15px;
  }
}
</style>
